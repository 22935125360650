<script>
  import { onDestroy, setContext } from "svelte";

  import { HeroGeneric, HeroSecondary, HeroPrimary } from "@ObamaFoundation/of-design-system";

  import FilterList from "$lib/components/FilterList.svelte";
  import { filterItemsStore } from "$lib/stores";
  import { flattenAppliedFilterGroups } from "$lib/utilities/filters";

  export let data;
  export let component = HeroGeneric;

  let filterListIsOpen = false;

  setContext("containerized", true);

  const { featuredImage, featuredStory, cta, theme } = data;
  const isTextOnly = !featuredImage && !featuredStory && !cta;

  /**
   * To avoid regressions we remove the margin on demand for components and cases with extra margins
   *
   * TODO: add tests for this once https://github.com/ObamaFoundation/obamaorg-swa/pull/2427 is merged in
   * */
  const getMargin = () => {
    if (component === HeroSecondary && (featuredImage || !theme)) {
      return "mb-0";
    } else if ((component === HeroGeneric || component === HeroPrimary) && isTextOnly) {
      return "mb-0";
    }
    return "mb-15 md:mb-20 lg:mb-25";
  };

  function toggleFilterList() {
    filterListIsOpen = true;
  }

  let activeFilters;
  const unsubscribe = filterItemsStore.subscribe((store) => {
    if (data.filters) {
      activeFilters = flattenAppliedFilterGroups(store);
    }
  });

  const handleDeselectFilterItem = (e) => {
    filterItemsStore.update((store) =>
      store.map((currentFilterGroup) => {
        currentFilterGroup.bodyProps.options.forEach((option) => {
          if (option.id === e.detail.filterItemId) {
            option.isChecked = false;
          }
        });
        return currentFilterGroup;
      })
    );
  };

  onDestroy(unsubscribe);
</script>

<!-- TODO: Remove margins from this component.
If this is wrapping a single component the margins should be handled on the component itself -->
<div class="-mt-[var(--nav-height)] {getMargin()}">
  <svelte:component
    this={component}
    {...data}
    filterItems={activeFilters}
    class="pt-[var(--nav-height)]"
    on:toggleFilterList={toggleFilterList}
    on:deselectFilterItem={handleDeselectFilterItem}
  />
</div>

{#if data.filters}
  <FilterList rawFilters={data.filters} bind:filterListIsOpen />
{/if}
