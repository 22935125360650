import { getYoutubeThumbnailUrlByVideoId } from "$lib/utilities/components/embeds";

export const getVideoComponentPropsForVideoContentType = (data) => {
  const defaultThumbnail = getYoutubeThumbnailUrlByVideoId(data.fields?.embedID);

  return {
    header: data.fields?.videoTitle,
    body: data.fields?.description,
    caption: data.fields?.caption,
    loopSection: {
      startTime: data.fields?.loopStart || 0,
      endTime: data.fields?.loopEnd
    },
    videoCoverImage: defaultThumbnail
      ? {
          src: defaultThumbnail,
          alt: ""
        }
      : undefined,
    videoId: data.fields?.embedID
  };
};
