export function generateFilterOptions(headline, body, bodyProps, active) {
  return {
    headline,
    active: active ?? false,
    body,
    bodyProps
  };
}

export function paginateJobs(jobs, jobsPerPage, upperBound) {
  let index = -1;

  return jobs.map((department) => {
    const jobs = department.jobs.filter(() => {
      index++;
      return index >= upperBound - jobsPerPage && index < upperBound;
    });

    return {
      ...department,
      jobs
    };
  });
}
