/**
 * Extracts the YouTube video ID from a given URL.
 *
 * @param {string} url - The YouTube video URL.
 * @returns {string|null} - The YouTube video ID if found, otherwise null.
 */
export const extractYoutubeVideoID = (url) => {
  if (!url) {
    return null;
  }

  const youtubeRegex =
    /* eslint-disable-next-line */
    /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)\&?/;

  const found = url.match(youtubeRegex);

  return found ? found[1] : null;
};

/**
 * Returns the YouTube thumbnail URL for a given video ID.
 *
 * @param {string} videoId - The ID of the YouTube video.
 * @returns {string} The URL of the YouTube thumbnail.
 */
export const getYoutubeThumbnailUrlByVideoId = (videoId) =>
  `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`;

/**
 * Returns the YouTube thumbnail URL for a given YouTube video URL.
 * @param {string} url - The YouTube video URL.
 * @returns {string|null} The YouTube thumbnail URL or null if the video ID cannot be extracted.
 */
export const getYoutubeThumbnailUrl = (url) => {
  let videoId = extractYoutubeVideoID(url);

  return videoId ? getYoutubeThumbnailUrlByVideoId(videoId) : null;
};

const VALID_SPOTIFY_EMBED_TYPES = ["playlist", "episode", "show", "artist", "album", "track"];

export const getSpotifyEmbedType = (url) => {
  const urlParts = url.split("/");
  const type = urlParts[urlParts.length - 2];
  return VALID_SPOTIFY_EMBED_TYPES.includes(type) ? type : null;
};

/**
 * Returns the Spotify iframe source URL from the share URL.
 *
 * @param {string} url - The Spotify share URL.
 * @returns {string} The Spotify iframe source URL.
 */
export const getSpotifyIframeSrcFromShareUrl = (url) => {
  const urlParts = url.split("/");
  const type = getSpotifyEmbedType(url);
  const id = urlParts[urlParts.length - 1];

  return type && id ? `https://open.spotify.com/embed/${type}/${id}` : null;
};
