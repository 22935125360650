import {
  buildSrc,
  srcSetFromWidths,
  sizesForBreakpoints,
  SRCSET_WIDTHS,
  mapTags
} from "$lib/utilities";
import { textToId } from "$lib/utilities/dom-helpers";
import {
  HeroHome,
  HeroPrimary,
  HeroSecondary,
  HeroGeneric,
  HeroArticle
} from "@ObamaFoundation/of-design-system";
import { getImageAspectRatio } from "../components/image-helpers";
import { isExternalUrl } from "../url-helpers";
import { getVideoComponentPropsForVideoContentType } from "$lib/utilities/contentTypes/video";

export const HERO_VARIANTS = {
  HOME_UPPER: "Homepage - Upper case",
  HOME_LOWER: "Homepage - Lower case",
  PRIMARY: "Primary",
  SECONDARY: "Secondary",
  DETAIL: "Detail",
  FEATURED_STORY: "Featured Story"
};

export const HERO_COMPONENT_MAP = {
  [HERO_VARIANTS.HOME_UPPER]: HeroHome,
  [HERO_VARIANTS.HOME_LOWER]: HeroHome,
  [HERO_VARIANTS.PRIMARY]: HeroPrimary,
  [HERO_VARIANTS.SECONDARY]: HeroSecondary,
  [HERO_VARIANTS.DETAIL]: HeroGeneric,
  [HERO_VARIANTS.FEATURED_STORY]: HeroArticle
};

export const HERO_VARIANTS_WITH_THEME = [
  HERO_VARIANTS.HOME_UPPER,
  HERO_VARIANTS.HOME_LOWER,
  HERO_VARIANTS.FEATURED_STORY,
  HERO_VARIANTS.SECONDARY
];

const HERO_IMAGE_SIZES = {
  [HERO_VARIANTS.DETAIL]: { sm: "100vw", md: "50vw" },
  [HERO_VARIANTS.FEATURED_STORY]: { sm: "100vw", md: "50vw" }
};

/**
 *
 * @param {*} fields
 * @param {*} pageData
 * @returns {{
 *  title: string,
 *  subtitle?: string,
 *  theme?: string,
 *  featuredImage?: Object,
 *  cta?: Object
 * }}
 */
export const getHeroBaseProps = (fields, pageData) => {
  const widthsAt = HERO_IMAGE_SIZES[fields.formFactor];
  const sizes = sizesForBreakpoints(widthsAt);
  const showTheme = HERO_VARIANTS_WITH_THEME.includes(fields?.formFactor);

  return {
    title: fields?.title,
    subtitle: fields?.subheadline,
    theme: showTheme ? pageData?.fields?.theme || fields?.theme : null,
    featuredImage: fields?.featuredImage
      ? {
          src: buildSrc(fields.featuredImage.fields?.imageAsset, { w: 3000 }),
          alt: fields.featuredImage.fields?.altText,
          srcset: srcSetFromWidths(
            fields.featuredImage.fields?.imageAsset?.fields?.file?.url,
            SRCSET_WIDTHS
          ),
          sizes,
          eagerLoad: true,
          theme: fields.featuredImage.fields.theme // TODO: might need to override in cases where the hero itself is themed
        }
      : null,
    cta: fields?.callToAction
      ? {
          text: fields.callToAction.fields?.text,
          url: fields.callToAction.fields?.destination?.fields?.url,
          isExternal: isExternalUrl(fields.callToAction.fields?.destination?.fields?.url),
          id: fields?.title ? `hero-cta-${textToId(fields.title)}` : null
        }
      : null
  };
};

/**
 *
 * @param {*} fields
 * @param {*} pageData
 * @returns
 */
export const getHeroVariantProps = (fields, pageData) => {
  const formFactorProps = {
    [HERO_VARIANTS.HOME_UPPER]: {
      ...getHeroBaseProps(fields, pageData),
      hasVariableSizeText: true
    },
    [HERO_VARIANTS.HOME_LOWER]: {
      ...getHeroBaseProps(fields, pageData)
    },
    [HERO_VARIANTS.PRIMARY]: {
      ...getHeroBaseProps(fields, pageData),
      animatedTitle: fields.animatedHeadline
    },
    [HERO_VARIANTS.SECONDARY]: {
      ...getHeroBaseProps(fields, pageData),
      featuredStory: fields.featuredStory
        ? {
            title: fields.featuredStory.fields.text,
            subtitle: fields.featuredStory.fields.description,
            cta: {
              text: "Learn more",
              url: fields.featuredStory.fields.url,
              id: `hero-cta-${textToId(fields.featuredStory.fields.text)}`
            },
            image: {
              src: buildSrc(fields.featuredStory.fields.image, { w: 1500 }),
              alt: fields.featuredStory.fields.image.fields.description,
              srcset: srcSetFromWidths(
                fields.featuredStory.fields.image.fields.file.url,
                SRCSET_WIDTHS
              ),
              sizes: sizesForBreakpoints({ sm: "100vw", md: "50vw" })
            }
          }
        : null,
      featuredVideo: fields.featuredVideo
        ? getVideoComponentPropsForVideoContentType(fields.featuredVideo)
        : null
    },
    [HERO_VARIANTS.DETAIL]: {
      ...getHeroBaseProps(fields, pageData),
      secondaryText: fields.secondaryText
    },
    [HERO_VARIANTS.FEATURED_STORY]: {
      ...getHeroBaseProps(fields, pageData),
      imageVariant:
        fields.featuredImage &&
        getImageAspectRatio(fields.featuredImage, fields.featuredImage.fields.aspectRatio),
      tags: mapTags(pageData?.fields?.contentTags, 3, true)
    }
  };

  // filter is the props passed into FilterList ContentType
  const filters = fields.filters;

  return { ...formFactorProps[fields.formFactor], filters };
};
