/**
 *
 * @param {*} tags the contentTags attached ot the entry
 * @param {number} numberOfTags the desired number of tags; default is 1 as the assumption is that you want at least one tag
 * @param {boolean} useHref whether or not to link out to the "stories" page for the tag
 * @returns tags mapped in the form {name: tagName, href: tagPageLink}
 */
export const mapTags = (tags, numberOfTags = 1, useHref = false) => {
  return tags?.slice(0, numberOfTags).map((tag) => {
    return {
      name: tag.fields.name,
      href: useHref
        ? `/stories/${tag.fields.name.toLowerCase().trim().replaceAll(/\s+/g, "-")}`
        : undefined
    };
  });
};
