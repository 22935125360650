<script>
  import { onDestroy, onMount } from "svelte";

  import { Filters, Button, Drawer, Checkbox } from "@ObamaFoundation/of-design-system";

  import { getTotalNumberOfSelectedFilters } from "$lib/utilities/filters";
  import { generateFilterOptions } from "$lib/utilities/careers.js";
  import { textToId } from "$lib/utilities/dom-helpers";
  import { filterItemsStore } from "$lib/stores";

  /**
   * The filter options as received from Contentful. They are used to generate the filter props
   * we pass to the design system components on mount and reset filter state to its initial state.
   * @type {Object}
   */
  export let rawFilters = undefined;

  /**
   * The transformed filter options that will appear in the filter list.
   * @type {Object[]}
   */
  export let filterListIsOpen = false;

  /**
   * Filters that have been selected but not yet submitted.
   * @type {Object[]}
   */
  let stagedFilters = [];

  /**
   * updates as selections are changed here
   * or if store is otherwise modified (e.g., when an active filter is removed from Hero)
   *
   * @type {number}
   */
  $: numberOfSelectedOptions = getTotalNumberOfSelectedFilters(stagedFilters);
  const unsubscribe = filterItemsStore.subscribe((store) => {
    numberOfSelectedOptions = getTotalNumberOfSelectedFilters(store);
  });

  const toggleFilterList = (e) => {
    filterListIsOpen = e.detail.drawerIsOpen;
  };

  const transformRawFiltersToFilterOptions = () =>
    rawFilters?.fields.items?.map((filterItem) =>
      generateFilterOptions(filterItem.fields.name, Checkbox, {
        options: filterItem.fields.tags.map((tag) => {
          return {
            label: tag.fields.name,
            id: `filter-${textToId(tag.fields.name.trim())}`,
            isChecked: false
          };
        }),
        name: filterItem.fields.name,
        componentClass: "lg:pt-[4px] lg:pb-4"
      })
    ) || [];

  const resetFilters = () => {
    stagedFilters = transformRawFiltersToFilterOptions();
  };

  const handleClear = () => {
    resetFilters();
  };

  const handleCheckBoxSelectionGroup = (e) => {
    stagedFilters = e.detail.items;
  };

  const handleApply = () => {
    filterItemsStore.set(stagedFilters);
    filterListIsOpen = false;
  };

  onMount(() => {
    resetFilters();
  });

  onDestroy(unsubscribe);
</script>

<Drawer on:drawerIsOpen={toggleFilterList} open={filterListIsOpen}>
  <div slot="header" class="text-black" aria-live="polite">
    <p class="inline label">Filter</p>
    {#if numberOfSelectedOptions > 0}
      <p class="inline label">
        <span>
          {`(${numberOfSelectedOptions} selected): `}
        </span>
        <Button
          id="clear-filters"
          class="underline"
          ariaLabel="Clear All Selected Filters"
          on:click={handleClear}>Clear All</Button
        >
      </p>
    {/if}
  </div>
  <Filters
    itemContainerClass="text-black"
    slot="filters"
    on:checkBoxSelectionGroup={handleCheckBoxSelectionGroup}
    componentName="checkbox"
    filters={stagedFilters}
  />
  <Button
    slot="button"
    on:click={handleApply}
    id="apply-filters"
    disabled={numberOfSelectedOptions < 1}
    variant="filled"
    label="Apply"
    buttonClass="w-full justify-center"
  />
</Drawer>
